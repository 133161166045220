export const ENTRY_INIT = {
    dateofwork: '',
    starttime: '',
    endtime: '',
    lunchtime: '',
    jobname: '',
    task: '',
    notes: ''
};
export const USER_INIT = {
    firstname: '',
    lastname: '',
    email: '',
    confirmemail: '',
    password: '',
    confirmpassword: '',
};
export const LUNCHTIMES = [
    0,
    15,
    30,
    45,
    60,
    90
];
