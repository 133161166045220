import React from "react";

function AppCss() {
    return (
        <style jsx="true">{`
            body {
                margin: 0
            }
        `}
        </style>
    )
}

export default AppCss;
